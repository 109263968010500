import mainjson from '../config/main';
export default function GetData() {
        return {
        "apitype":"rewards",
        "id":"reward_id",
        "subidname":"t",
        "options":["view","edit","insert"],
        "nameSingle":"reward",
        "nameMultiple":"rewards"
    ,
        "fields":{
            "field1":{
                "name":"Title",
                "field":"reward_title",
                "type":"TextInputMultiLanguage",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Body",
                "field":"reward_body",
                "type":"TextAreaMultiLanguage",
                "required":true,
                "list":false
            },
            "field3":{
                "name":"Image",
                "field":"reward_image",
                "type":"ImageUpload",
                "required":false,
                "list":true,
                "resize":{
                    width:956,
                    height:604,
                    type:"fill",
                    quality:0.75,
                    bgcolor:"#ffffff"
                },
            },
            "field4":{
                "name":"Type",
                "field":"reward_type",
                "type":"DropDown",
                "options": {
                    "event_noredeem":"On event - Coupon",
                    "event_redeem":"On event - Redeem",
                    "remote":"Remote - Coupon"
                },
                "list":true,
                "required":true,

            },
            
            "field5":{
                "name":"Wristband color",
                "field":"reward_color",
                "type":"DropDown",
                "options": {
                   // "E0":"Red",
                    "74":"Orange",
                    "54":"Yellow",
                    "1C":"Green",
                    "1F":"Cyan",
                    "03":"Blue",
                    "E3":"Magenta",
                    "5F":"White",
                    "00":"Black",
                    "7C":"Lime",
                    "0F":"Bluegreen",
                    "43":"Purple",

                },
                "list":true,
                "required":true,

            },
            "field6":{
                "name":"Order",
                "field":"reward_level",
                "type":"TextInput",
                "subtype":"Number",
                "list":true,
                "required":true,
                "default":1,

            },
            "field7":{
                "name":"Nr available",
                "field":"reward_available",
                "type":"TextInput",
                "subtype":"Number",
                "list":true,
                "required":true,
                "default":10

            },
            "field8":{
                "name":"Limited validity",
                "field":"reward_limitedvalidity",
                "type":"DropDown",
                "options": {
                    "0":"Unlimited valid",
                    "1":"Valid between start and enddate",
                },
                "list":true,
                "required":true,

            },
            "field9":{
                "name":"Start validity (UTC)",
                "field":"reward_start",
                "type":"DateTimeInput",
                "required":false,
                "list":false
            },
            "field10":{
                "name":"End validity (UTC)",
                "field":"reward_end",
                "type":"DateTimeInput",
                "required":false,
                "list":false
            },
            "field11":{
                "name":"Linked coupon group",
                "field":"reward_coupongroup_id",
                "emptyfirstoption":"No coupon linked",
                "emptyfirstoptionvalue":"-1",
                "type":"DropDown",
                "options": "coupongroups",
                "list":true,
            }, 
            "field12":{
                "name":"Reward active in data control",
                "field":"reward_active",
                "type":"DropDown",
                "options": {
                    "1":"Reward is active",
                    "0":"Reward is disabled",
                },
                "list":true,
                "required":true,
            }, 
        }
    }
}